<template>
    <div>
        <!-- CRU Modal -->
        <b-modal
            id="cru_modal"
            :title="`${
                cru_modal.criteria.id
                    ? `Update: ${cru_modal.criteria.name}`
                    : 'Create Judging Criteria'
            }`"
            size="lg"
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
        >
            <div>
                <b-row>
                    <b-col>
                        <validation-observer
                            v-slot="{ handleSubmit }"
                            ref="formValidator"
                        >
                            <b-form
                                role="form"
                                @submit.prevent="handleSubmit(submit_form)"
                            >
                                <input
                                    type="submit"
                                    ref="cru_modal_form_submit_button"
                                    hidden
                                />
                                <b-row>
                                    <b-col sm="12" md="8">
                                        <base-input
                                            label="Name"
                                            :rules="{
                                                required: true,
                                            }"
                                            :pill="true"
                                            name="Name"
                                            placeholder="Enter a name"
                                            :disabled="$apollo.loading"
                                            v-model="cru_modal.criteria.name"
                                        >
                                        </base-input>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col sm="12" md="4">
                                        <base-input
                                            label="Type"
                                            :rules="{ required: true }"
                                            :pill="true"
                                            name="Type"
                                            :disabled="$apollo.loading"
                                        >
                                            <el-select
                                                class="form"
                                                v-model="
                                                    cru_modal.criteria.type
                                                "
                                                default-first-option
                                                filterable
                                            >
                                                <el-option
                                                    v-for="option in cru_modal.type_options"
                                                    :key="option.id"
                                                    :label="option.name"
                                                    :value="option.id"
                                                >
                                                </el-option>
                                            </el-select>
                                        </base-input>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col sm="4">
                                        <el-tooltip
                                            content="The maxiumum score that a team can score"
                                            placement="top"
                                        >
                                            <base-input
                                                label="Maximum Score"
                                                :rules="{
                                                    required: true,
                                                    numeric: true,
                                                    min_value: 1,
                                                }"
                                                :pill="true"
                                                type="number"
                                                name="Place"
                                                placeholder="Enter a Score"
                                                :disabled="$apollo.loading"
                                                v-model="
                                                    cru_modal.criteria.max_score
                                                "
                                            >
                                            </base-input>
                                        </el-tooltip>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col>
                                        <html-editor
                                            :pill="true"
                                            label="Judging Criteria detail description"
                                            class="mb-3"
                                            name="Detailed Description"
                                            placeholder="A detail description"
                                            v-model="
                                                cru_modal.criteria.description
                                            "
                                            :disabled="$apollo.loading"
                                        >
                                        </html-editor>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </validation-observer>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class="text-right">
                        <base-button
                            type="danger"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="$bvModal.hide('cru_modal')"
                            :disabled="cru_modal.loading"
                            >Close
                        </base-button>
                        <base-button
                            type="success"
                            :pill="true"
                            class="my-4 btn-success"
                            @click="cru_modal_save()"
                            :disabled="cru_modal.loading"
                            :loading="cru_modal.loading"
                            :success="cru_modal.success"
                            >Save
                        </base-button>
                    </b-col>
                </b-row>
            </div>
        </b-modal>

        <!-- Delete Modal -->
        <b-modal
            id="d_modal"
            title="Confirm"
            size="sm"
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
        >
            <div class="text-center">
                <h1>Are you sure?</h1>
                <p>
                    You are about to delete the criteria for position:
                    {{ d_modal.criteria.position }}
                </p>
                <base-button
                    type="danger"
                    :pill="true"
                    class="my-4 btn-success"
                    @click="delete_criteria()"
                    :loading="d_modal.loading"
                    :disabled="d_modal.loading"
                    :success="d_modal.success"
                >
                    Delete
                </base-button>
                <base-button
                    type="primary"
                    :pill="true"
                    class="my-4 btn-success"
                    @click="$bvModal.hide('d_modal')"
                    :disabled="d_modal.loading"
                    >Cancel
                </base-button>
            </div>
        </b-modal>

        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="6">
                        <h1>Hackathon Judging Criteria</h1>
                    </b-col>
                    <b-col cols="6" class="text-right">
                        <el-tooltip
                            content="Create Judging Criteria"
                            placement="top"
                            v-if="is_hackathon_manager || is_hackathon_creator"
                        >
                            <base-button
                                type="primary"
                                round
                                icon
                                size="sm"
                                @click="cru_modal_show()"
                            >
                                <span class="btn-inner"
                                    ><i class="fa fa-plus"></i
                                ></span>
                            </base-button>
                        </el-tooltip>
                    </b-col>
                </b-row>

                <div v-if="$apollo.loading && table_data.length == 0">
                    <b-row
                        class="mt-2 d-flex align-items-center justify-content-center"
                    >
                        <b-col
                            class="d-flex align-items-center justify-content-center"
                        >
                            <Transition>
                                <h1>
                                    <i class="fas fa-spinner fa-spin ml-1"></i>
                                </h1>
                            </Transition>
                        </b-col>
                    </b-row>
                </div>
                <b-row v-else>
                    <b-col>
                        <el-table
                            class="table-responsive align-items-center table-flush no-lines"
                            header-row-class-name="thead-light"
                            row-class-name="table-rows"
                            :data="table_data"
                            :empty-text="`${
                                is_hackathon_manager || is_hackathon_creator
                                    ? 'No judging criteria yet, use the \'+\' button to add judging criteria.'
                                    : 'Judging criteria will display soon...'
                            }`"
                        >
                            <el-table-column
                                label="Name"
                                min-width="250px"
                                sortable
                            >
                                <template v-slot="{ row }">
                                    <p class="mb-0">
                                        {{ row.name }}
                                    </p>
                                </template>
                            </el-table-column>

                            <el-table-column
                                label="Description"
                                min-width="350px"
                                sortable
                            >
                                <template v-slot="{ row }">
                                    <p
                                        class="mb-0"
                                        v-html="row.description"
                                    ></p>
                                </template>
                            </el-table-column>

                            <el-table-column
                                label="Type"
                                min-width="140px"
                                sortable
                                v-if="
                                    is_hackathon_manager || is_hackathon_creator
                                "
                            >
                                <template v-slot="{ row }">
                                    <p
                                        class="mb-0"
                                        v-html="row.type_display"
                                    ></p>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="Max Score"
                                min-width="120px"
                                sortable
                            >
                                <template v-slot="{ row }">
                                    <p class="mb-0" v-html="row.max_score"></p>
                                </template>
                            </el-table-column>

                            <el-table-column
                                min-width="100px"
                                align="center"
                                v-if="
                                    is_hackathon_manager || is_hackathon_creator
                                "
                            >
                                <div slot-scope="{ row }" class="table-actions">
                                    <el-tooltip content="Edit" placement="top">
                                        <a
                                            href="#!"
                                            @click.prevent="cru_modal_show(row)"
                                            class="table-action"
                                            data-toggle="tooltip"
                                            data-original-title="Update Judging Criteria"
                                        >
                                            <i class="fas fa-edit"></i>
                                        </a>
                                    </el-tooltip>
                                    <el-tooltip
                                        content="Delete"
                                        placement="top"
                                    >
                                        <a
                                            href="#!"
                                            @click.prevent="d_modal_show(row)"
                                            class="table-action"
                                            data-toggle="tooltip"
                                            data-original-title="Update Judging Criteria"
                                        >
                                            <i class="fas fa-trash"></i>
                                        </a>
                                    </el-tooltip>
                                </div>
                            </el-table-column>
                        </el-table>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>
// Modules
import {
    Table,
    TableColumn,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    Tooltip,
    Select,
    Option,
} from "element-ui";
import HtmlEditor from "@/components/Inputs/HtmlEditor";

// Queries
import { GET_HACKATHON_JUDGING_CRITERIA } from "@/graphql/queries";

// Mutations
import {
    CREATE_HACKATHON_JUDGING_CRITERIA,
    UPDATE_HACKATHON_JUDGING_CRITERIA,
    DELETE_HACKATHON_JUDGING_CRITERIA,
} from "@/graphql/mutations";

export default {
    name: "HackthonCrudJudgingCriteriaTable",
    components: {
        [Tooltip.name]: Tooltip,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        [Select.name]: Select,
        [Option.name]: Option,
        HtmlEditor,
    },
    props: {
        hackathon_id: {
            type: String,
            description: "The Id of the hackathon being viewed.",
            default: null,
        },
    },
    apollo: {
        get_hackathon_judging_criteria: {
            query: GET_HACKATHON_JUDGING_CRITERIA,
            result(res) {
                let flattened = graph_utils.flattened_response(res.data);
                this.table_data = [];
                flattened.allHackathonJudgingCriteria.forEach((el) => {
                    this.table_data.push({
                        id: el.id,
                        name: el.name,
                        type: el.type,
                        type_display: el.typeDisplay,
                        max_score: el.maxScore,
                        description: el.description,
                    });
                });

                this.is_hackathon_creator =
                    res.data.hackathonHackathon.isCreator;
                this.is_hackathon_manager =
                    res.data.hackathonHackathon.isManager;
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    hackathon_id: this.hackathon_id,
                };
            },
            update(data) {
                this.apollo_data.get_hackathon_judging_criteria = data;
            },
            skip: true,
        },
    },
    data() {
        return {
            apollo_data: {
                get_hackathon_judging_criteria: null,
            },
            table_data: [], // All the criteria table data is in here.
            cru_modal: {
                loading: false,
                success: false,

                type_options: [
                    {
                        id: "BINARY",
                        name: "Yes/No",
                    },
                    {
                        id: "SCORE",
                        name: "Score out of max",
                    },
                ],

                criteria: {
                    id: null,
                    description: "",
                    max_score: 20,
                    name: null,
                    type: null,
                },
            },
            d_modal: {
                loading: false,
                success: false,
                criteria: {
                    id: null,
                    position: null,
                    description: null,
                },
            },

            is_hackathon_manager: false,
            is_hackathon_creator: false,
        };
    },
    methods: {
        // Modals

        // cru_modal
        cru_modal_show(criteria) {
            if (this.is_hackathon_manager) {
                if (criteria) {
                    this.cru_modal.criteria = criteria;
                } else {
                    this.cru_modal.criteria = {
                        id: null,
                        description: "",
                        max_score: 20,
                        name: null,
                        type: null,
                    };
                }
                this.$bvModal.show("cru_modal");
            } else {
                this.cru_modal.criteria = {
                    id: null,
                    description: "",
                    max_score: 20,
                    name: null,
                    type: null,
                };
            }
        },
        cru_modal_save() {
            this.cru_modal.loading = true;

            let vars = {
                name: this.cru_modal.criteria.name,
                description: this.cru_modal.criteria.description,
                type: this.cru_modal.criteria.type,
                max_score: Number(this.cru_modal.criteria.max_score),
            };

            let mutation = CREATE_HACKATHON_JUDGING_CRITERIA;
            if (this.cru_modal.criteria.id) {
                mutation = UPDATE_HACKATHON_JUDGING_CRITERIA;
                vars.criteria_id = this.cru_modal.criteria.id;
            } else {
                vars.hackathon_id = this.hackathon_id;
            }

            this.$apollo
                .mutate({
                    mutation: mutation,
                    variables: vars,
                })
                .then(() => {
                    this.$apollo.queries.get_hackathon_judging_criteria.refetch();

                    this.cru_modal.loading = false;

                    this.cru_modal.success = true;
                    setTimeout(() => {
                        this.cru_modal.success = false;
                    }, 1000);
                    setTimeout(() => {
                        this.$bvModal.hide("cru_modal");
                    }, 800);

                    this.$notify({
                        message: `Successfully saved criteria`,
                        timeout: 5000,
                        icon: "ni ni-check-bold",
                        type: "success",
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.cru_modal.loading = false;
                    this.$notify({
                        message: `Could not save criteria`,
                        timeout: 5000,
                        icon: "ni ni-cross-bold",
                        type: "danger",
                    });
                });
        },

        // d_modal
        d_modal_show(criteria) {
            if (this.is_hackathon_manager) {
                if (criteria) {
                    this.d_modal.criteria = criteria;
                } else {
                    this.d_modal.criteria = {
                        id: null,
                        description: "",
                        position: null,
                    };
                }
                this.$bvModal.show("d_modal");
            } else {
                this.d_modal.criteria = {
                    id: null,
                    description: "",
                    position: null,
                };
            }
        },
        delete_criteria() {
            this.d_modal.loading = true;
            this.$apollo
                .mutate({
                    mutation: DELETE_HACKATHON_JUDGING_CRITERIA,
                    variables: {
                        criteria_id: this.d_modal.criteria.id,
                    },
                })
                .then(() => {
                    this.$apollo.queries.get_hackathon_judging_criteria.refetch();

                    this.d_modal.loading = false;

                    this.d_modal.success = true;
                    setTimeout(() => {
                        this.d_modal.success = false;
                    }, 1000);
                    setTimeout(() => {
                        this.$bvModal.hide("d_modal");
                    }, 800);

                    this.$notify({
                        message: `Successfully deleted judging criteria`,
                        timeout: 5000,
                        icon: "ni ni-check-bold",
                        type: "success",
                    });
                })
                .catch((err) => {
                    console.log(err);
                    this.d_modal.loading = false;
                    this.$notify({
                        message: `Could not delete judging criteria`,
                        timeout: 5000,
                        icon: "ni ni-cross-bold",
                        type: "danger",
                    });
                });
        },
    },
    watch: {
        hackathon_id() {
            if (this.hackathon_id) {
                graph_utils.enable_query(
                    this.$apollo.queries.get_hackathon_judging_criteria
                );
            } else {
                graph_utils.disable_query(
                    this.$apollo.queries.get_hackathon_judging_criteria
                );
            }
        },
    },
    mounted() {
        if (!this.$apollo.queries.get_hackathon_judging_criteria.skip) {
            this.$apollo.queries.get_hackathon_judging_criteria.refetch();
        }
    },
    created() {
        // setting this queries fetch policy
        graph_utils.set_fetch_cache_and_network(
            this.$apollo.queries.get_hackathon_judging_criteria
        );
    },
};
</script>

<style>
.el-input__inner {
    border-radius: 10rem;
}

.el-table .cell {
  white-space: normal;    
  word-break: keep-all; 
}
</style>
